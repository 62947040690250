import React from 'react'
import styled from 'styled-components'

import { Container } from './styles/Container'

const StyledFooter = styled.footer`
  margin-bottom: 45px;
  padding: ${props => props.theme.remScale[7]} 0;
  background-color: ${props => props.theme.neutral1};
  color: ${props => props.theme.white};
  font-size: ${props => props.theme.remScale[4]};
  text-align: center;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 0;
    text-align: left;
  }
`
const Footer = () => (
  <StyledFooter>
    <Container>
      © 2019 Level Up Cyber Security LLC. All rights reserved.
    </Container>
  </StyledFooter>
)

export default Footer
