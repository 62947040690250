import styled, { css } from 'styled-components'

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 ${props => props.theme.remScale[5]};

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    ${({ boxed, theme }) =>
      boxed &&
      css`
        padding: ${theme.remScale[10]};
        background: ${theme.white};
        box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25),
          0 50px 200px 0 hsla(222, 44%, 40%, 0.3);
        border-radius: 5px;
      `}
  }
`
