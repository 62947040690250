import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Button } from './styles/Button'

const Nav = styled.nav`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background: ${props => props.theme.neutral1};
  z-index: 10;

  ul {
    display: flex;
    justify-content: space-between;
    max-width: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    list-style: none;
    margin: 0;
  }

  li.featured-parent {
    padding: 0 ${props => props.theme.remScale[1]};
  }

  .nav-link {
    position: relative;
    width: 100%;
    background-image: none;
    padding: ${({ theme }) => `${theme.remScale[5]} ${theme.remScale[2]}`};
    color: ${props => props.theme.white};
    font-weight: ${props => props.theme.fontWeight};
    font-size: ${props => props.theme.remScale[4]};
    text-align: center;
    line-height: ${props => props.theme.lineHeightTight};
  }

  .featured {
    padding: ${({ theme }) => `${theme.remScale[2]} ${theme.remScale[3]}`};
    font-weight: ${props => props.theme.fontWeight};
    font-size: ${props => props.theme.remScale[4]};
  }

  .nav-link.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: ${props => props.theme.primary3};
  }

  .name {
    display: none;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    position: static;
    background: transparent;
    width: auto;

    li {
      margin: 0 ${props => props.theme.remScale[2]};
    }

    li.featured-parent {
      padding: 0 ${props => props.theme.remScale[2]};
    }

    .nav-link {
      padding: ${({ theme }) => `${theme.remScale[1]} ${theme.remScale[2]}`};
      color: ${props => props.theme.black};
      font-size: ${props => props.theme.remScale[5]};
      line-height: ${props => props.theme.lineHeight};
      background-image: linear-gradient(
        transparent calc(100% - 3px),
        ${props => props.theme.primary1} 3px
      );
    }

    .featured {
      padding: ${({ theme }) => `${theme.remScale[3]} ${theme.remScale[5]}`};
      font-weight: ${props => props.theme.fontWeightBold};
      font-size: ${props => props.theme.remScale[5]};
    }

    .nav-link.active {
      background-image: linear-gradient(
        transparent calc(100% - 3px),
        ${props => props.theme.primary3} 3px
      );
      background-size: 100% 100%;
    }

    .nav-link.active::after,
    li + li .nav-link::before {
      display: none;
    }

    .name {
      display: inline;
    }

    .mobile-name {
      display: none;
    }
  }
`

export const NavBar = ({ menuLinks }) => (
  <Nav aria-label="Primary Navigation">
    <ul>
      {menuLinks.map(({ path, name, mobileName, featured }) => {
        const Title = () => {
          return mobileName ? (
            <>
              <span className="mobile-name">{mobileName}</span>
              <span className="name">{name}</span>
            </>
          ) : (
            <span>{name}</span>
          )
        }

        return (
          <li key={name} className={featured ? 'featured-parent' : ''}>
            {featured ? (
              <Button
                as={Link}
                to={path}
                activeClassName="active"
                className="featured"
              >
                <Title />
              </Button>
            ) : (
              <Link to={path} activeClassName="active" className="nav-link">
                <Title />
              </Link>
            )}
          </li>
        )
      })}
    </ul>
  </Nav>
)
