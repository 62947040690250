import { css } from 'styled-components'

export const Utilities = css`
  .w-half {
    width: 50%;
  }
  .w-third {
    width: 33%;
  }
`
