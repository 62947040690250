const theme = {
  /**
   * Colors
   * 1 = darkest
   */
  primary1: '#396AD1',
  primary2: '#78A0F5',
  primary3: '#A4BEF5',
  primary4: '#C9D7F5',
  primary5: '#E0EAFF',
  primary6: '#EBEFF8',
  primary7: '#F7F9FC',
  secondary1: '#CC8B0C',
  secondary2: '#F2AB1F',
  secondary3: '#FFCB66',
  secondary4: '#FFD88C',
  secondary5: '#FFE9BF',
  secondary6: '#FFF6E3',
  neutral1: '#1A2A4D',
  neutral2: '#3C4C6E',
  neutral3: '#687694',
  neutral4: '#93A1BD',
  neutral5: '#CED4E0',
  neutral6: '#E8EBF2',
  neutral7: '#EFF1F6',
  danger1: '#CC0C0C',
  danger2: '#F21F1F',
  danger3: '#F06060',
  danger4: '#F58787',
  danger5: '#FFBFBF',
  danger6: '#FFE3E3',
  shadow1: 'hsla(221, 50%, 20%, 0.25)',
  shadow2: 'hsla(221, 50%, 20%, 0.4)',

  /**
   * Font families
   */
  fontFamily: 'Lato, sans-serif',

  /**
   * Font sizes
   */
  fontSizeBase: '18px',
  fontSize: '1rem',

  /**
   * Font weights
   */
  fontWeight: 400,
  fontWeightBold: 700,
  fontWeightBolder: 900,

  /**
   * Line heights
   */
  lineHeight: 1.5,
  lineHeightTight: 1.2,

  /**
   * Length / scale units
   * Each size is a multiple of 1.25 (e.g., len7 is 1rem * 1.25 * 1.25)
   */
  scale: [
    0.26,
    0.41,
    0.512,
    0.64,
    0.8,
    1,
    1.25,
    1.563,
    1.953,
    2.441,
    3.052,
    3.815,
    4.768,
    5.96,
    7.451,
    9.313,
    11.642,
    14.552,
    28.423,
    35.526,
    44.409,
  ],

  /**
   * Breakpoints
   */
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
}

theme.white = theme.primary7
theme.black = theme.neutral1
theme.remScale = theme.scale.map(step => `${step}rem`)
theme.emScale = theme.scale.map(step => `${step}em`)

export { theme }
