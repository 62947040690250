import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

import { Container } from './styles/Container'
import { NavBar } from './NavBar'
import logo from '../images/level-up-logo.svg'

const HeaderSection = styled.header`
  ${props =>
    props.absolute &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    `}

  border-top: ${props => props.theme.remScale[1]} solid
    ${props => props.theme.primary2};
  padding: ${props => props.theme.remScale[7]} 0;
  z-index: 10;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  background-image: none;
  z-index: 10;
`

const Logo = styled.img`
  width: auto;
  height: ${props => props.theme.remScale[7]};

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    height: ${props => props.theme.remScale[9]};
  }
`

const Header = ({ siteTitle, menuLinks, absolute }) => (
  <HeaderSection absolute={absolute}>
    <Container>
      <LogoLink to="/" aria-label={`${siteTitle} logo, Back to homepage`}>
        <Logo src={logo} alt={`${siteTitle} logo`} />
      </LogoLink>
      <NavBar menuLinks={menuLinks} />
    </Container>
  </HeaderSection>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
