import { createGlobalStyle } from 'styled-components'
import { dom } from '@fortawesome/fontawesome-svg-core'

import { ModernNormalize } from './modernNormalize'
import { Utilities } from './utilities'

export const GlobalStyle = createGlobalStyle`
  /**
   * Modern Normalize
   * https://github.com/sindresorhus/modern-normalize
   */
  ${ModernNormalize}

  /**
   * Typography
   * Uses a modular scale from https://type-scale.com/
   */
  html {
    font-size: ${props => props.theme.fontSizeBase};
  }

  body {
    background-color: ${props => props.theme.primary7};
    color: ${props => props.theme.black};
  }

  .base-text, body {
    font-family: ${props => props.theme.fontFamily};
    font-weight: ${props => props.theme.fontWeight};
    line-height: ${props => props.theme.lineHeight};
    font-size: ${props => props.theme.remScale[5]};
  }

  .base-text, p, ul, ol {
    margin-top: 0;
    margin-bottom: ${props => props.theme.emScale[6]};
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, h6,
  .hero {
    margin: ${props => props.theme.emScale[6]} 0 ${props =>
  props.theme.emScale[3]};
    font-weight: ${props => props.theme.fontWeightBolder};
    line-height: ${props => props.theme.lineHeightTight};
  }

  h1, .h1 {
    margin-top: 0;
    font-size: ${props => props.theme.remScale[8]};
  }

  h2, .h2 {
    font-size: ${props => props.theme.remScale[7]};
  }

  h3, .h3 {
    font-size: ${props => props.theme.remScale[6]};
  }

  h4, .h4, h5, .h5, .hero {
    font-size: ${props => props.theme.remScale[5]};
  }

  h6, .h6 {
    font-size: ${props => props.theme.remScale[5]};
  }

  .hero {
    font-weight: ${props => props.theme.fontWeightBold};
    line-height: ${props => props.theme.lineHeight};
  }

  small, .small {
    font-size: ${props => props.theme.emScale[4]};
  }

  .strong {
    font-weight: ${props => props.theme.fontWeightBold};
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    h1, .h1 {
      font-size: ${props => props.theme.remScale[10]};
    }

    h2, .h2 {
      font-size: ${props => props.theme.remScale[9]};
    }

    h3, .h3 {
      font-size: ${props => props.theme.remScale[8]};
    }

    h4, .h4 {
      font-size: ${props => props.theme.remScale[7]};
    }

    h5, .h5, .hero {
      font-size: ${props => props.theme.remScale[6]};
    }

    h6, .h6 {
      font-size: ${props => props.theme.remScale[5]};
    }
  }

  h1, .h1, h2, .h2 {
    max-width: 790px;
  }

  h3, .h3, h4, .h4 {
    max-width: 700px;
  }

  h5, .h5, .hero {
    max-width: 650px;
  }

  p,
  blockquote,
  ul,
  ol,
  dl {
    max-width: 600px;
  }

  li {
    margin-bottom: ${props => props.theme.remScale[1]};
  }

  /**
   * Text selection color
   */
  ::selection {
    background-color: ${props => props.theme.primary1};
    color: ${props => props.theme.white};
  }

  /**
   * Links
   */
  a {
    text-decoration: none;
    color: ${props => props.theme.primary1};
    font-weight: ${props => props.theme.fontWeightBold};
    background-image: linear-gradient(transparent calc(100% - 1px), currentColor 1px);
    background-size: 0% 100%;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 200ms;

    &:hover {
      background-size: 100% 100%;
    }
  }

  /**
   * Images
   */
  img {
    max-width: 100%;
    height: auto;
  }


  /**
   * Forms
   */
  label {
    position: relative;
    display: block;
    margin-bottom: ${props => props.theme.emScale[6]};
  }

  /**
   * Font Awesome styles, included for SSR
   */
  ${dom.css()}

  .fa-li {
    top: 6px;
  }

  /**
   * Utility classes
   */
  ${Utilities}
`
