import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO({ description, image, lang, meta, keywords, title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={({
        site: {
          siteMetadata: {
            defaultTitle,
            defaultDescription,
            siteUrl,
            defaultImage,
            author,
          },
        },
      }) => {
        const metaDescription = description || defaultDescription

        // use site title for home page
        let customTitle = title
        let titleTemplate = `%s | ${defaultTitle}`
        if (title === 'Home') {
          customTitle = defaultTitle
          titleTemplate = defaultTitle
        }

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={customTitle} // fall back to defaultTitle for the home page
            titleTemplate={titleTemplate}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: customTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: `${siteUrl}${image || defaultImage}`,
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                name: `twitter:creator`,
                content: author,
              },
              {
                name: `twitter:title`,
                content: customTitle,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          >
            <link rel="dns-prefetch" href="https://fonts.googleapis.com" />
            <link
              href="https://fonts.googleapis.com/css?family=Lato:400,400i,700,900"
              rel="stylesheet"
            />
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        defaultTitle: title
        siteUrl: url
        defaultDescription: description
        defaultImage: image
        author
      }
    }
  }
`
