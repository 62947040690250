import React from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider, css } from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleDoubleRight,
  faCheck,
  faThumbsUp,
  faExclamationCircle,
  faExclamationTriangle,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons'

import { theme } from './styles/theme'
import { GlobalStyle } from './styles/global'
import Header from './Header'
import Footer from './Footer'

config.autoAddCss = false
library.add(
  faAngleDoubleRight,
  faCheck,
  faThumbsUp,
  faExclamationCircle,
  faExclamationTriangle,
  faPaperPlane
)

const SimplePageWrapper = styled.main`
  margin-top: ${props => props.theme.remScale[8]};
  margin-bottom: ${props => props.theme.remScale[12]};

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    max-width: ${props => props.theme.breakpoints.md};
    margin-top: ${props => props.theme.remScale[10]};
    margin-left: auto;
    margin-right: auto;
  }
`

const Background = styled.div`
  ${({
    backgroundImage,
    backgroundOpacity,
    backgroundVerticalPosition,
    backgroundFullPage,
    theme,
  }) =>
    backgroundImage &&
    css`
      @media (min-width: ${theme.breakpoints.lg}) {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          background-repeat: no-repeat;
          background-position: center ${backgroundVerticalPosition};
          background-size: cover;
          z-index: -1;
          pointer-events: none;
          opacity: ${backgroundOpacity};

          ${(backgroundFullPage &&
            css`
              height: 100%;
              background-image: linear-gradient(
                  to top,
                  transparent 80%,
                  ${props => props.theme.primary7}
                ),
                url(${backgroundImage});
            `) ||
            css`
              height: 100vh;
              background-image: linear-gradient(
                  to top,
                  transparent 80%,
                  ${props => props.theme.primary7}
                ),
                linear-gradient(
                  to bottom,
                  transparent 90%,
                  ${props => props.theme.primary7}
                ),
                url(${backgroundImage});
            `}
        }
      }
    `}
`

const Layout = ({
  children,
  absoluteHeader = false,
  simplePage = true,
  backgroundImage = '',
  backgroundOpacity = 0.3,
  backgroundVerticalPosition = 'center',
  backgroundFullPage = true,
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              mobileName
              path
              featured
            }
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <Background
          backgroundImage={backgroundImage}
          backgroundOpacity={backgroundOpacity}
          backgroundVerticalPosition={backgroundVerticalPosition}
          backgroundFullPage={backgroundFullPage}
        >
          <Header
            siteTitle={data.site.siteMetadata.title}
            menuLinks={data.site.siteMetadata.menuLinks}
            absolute={absoluteHeader}
          />
          {simplePage ? (
            <SimplePageWrapper>{children}</SimplePageWrapper>
          ) : (
            children
          )}
          <Footer />
          <GlobalStyle />
        </Background>
      </ThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
