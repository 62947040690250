import styled, { css } from 'styled-components'

export const Button = styled.a`
  display: inline-block;
  margin: ${props => props.theme.remScale[1]} 0;
  padding: ${({ theme }) => `${theme.remScale[3]} ${theme.remScale[5]}`};
  background-color: ${props => props.theme.primary1};
  background-image: none;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 1px 4px ${props => props.theme.shadow1};
  color: ${props => props.theme.white};
  font-weight: ${props => props.theme.fontWeightBold};
  text-align: center;
  text-shadow: 0 1px 1px ${props => props.theme.shadow1};
  line-height: ${props => props.theme.lineHeightTight};
  cursor: pointer;
  transition: all 200ms ease;
  outline: none;

  &:hover {
    background-color: ${props => props.theme.neutral1};
    text-decoration: none;
  }

  &:focus {
    box-shadow: 0 0 3px 2px ${props => props.theme.secondary2};
  }

  ${props =>
    props.variant === 'ghost' &&
    css`
      background-color: transparent;
      border: 1px solid ${props => props.theme.primary1};
      color: ${props => props.theme.primary1};
      text-shadow: none;
      box-shadow: none;

      &:hover {
        background-color: ${props => props.theme.primary1};
        color: ${props => props.theme.white};
      }
    `}

  .svg-inline--fa {
    margin-left: ${props => props.theme.remScale[1]};
  }
`
